import AxiosInterceptor from "../utils/AxiosInterceptor";

const PaymentsService = {
    add: (value) => AxiosInterceptor().post(`/admin/payment/add`, value),
    edit: (value) => AxiosInterceptor().post(`/admin/payment/edit`, value),
    delete: (value) => AxiosInterceptor().post(`/admin/payment/delete`, value),
    getAll: (values) => AxiosInterceptor().post(`/admin/payment/list`, values),
    getById: (values) => AxiosInterceptor().post(`/admin/payment/detail`, values),
    changeStatus: (values) => AxiosInterceptor().post(`/admin/payment/change-status`, values)
};

export default PaymentsService;
