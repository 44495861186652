import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify"
// @mui
import {
    Card,
    Stack,
    Avatar,
    Button,
    Container,
    Typography,
    TextField,
    Link as MLink,
    Icon,
    Grid,
    CardContent,
    CardHeader,
    InputAdornment,
    IconButton,


    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Box
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../../../sections/@dashboard/user';


import Field from '../../../components/Inputs/Field';

// !utils
import { API_BASE, MOBILE_REGEX, NOSPACE_REGEX, PASSWORDS_REGEX } from '../../../utils/constants';
import { MESSAGE } from '../../../utils/validationMessage';
import { complexEmailRegex } from '../../../utils/emailCheck';
import errorHandler from '../../../utils/errorHandler';

// !states 
import subscriptionApi from "../../../api/subscriptionService"
import { getSubscriptionsData, deleteSubscription, changeStatus } from "../../../features/SubscriptionSlice"




const validationSchema = Yup
    .object()
    .shape({
        title: Yup.string().strict(true).max(30, "First name should be less than 30 chars").required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
        description: Yup.string().required(MESSAGE.REQUIRED).max(255),
        price: Yup.string().required(MESSAGE.REQUIRED),
        // type: Yup.string().required(MESSAGE.REQUIRED),
        period: Yup.string().required(MESSAGE.REQUIRED)
    });


export default function SubscriptionAddPage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { restros } = useSelector(state => state.restros)

    const [showPassword, setShowPassword] = useState(false);
    const initialValues = {
        title: "",
        description: "",
        price: "",
        period: "",
    };


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const response = await subscriptionApi.add(values);
                toast.success(response?.data?.message)
                navigate("/dashboard/price")
            } catch (err) {
                errorHandler(err);
                setSubmitting(false);
            }
        },
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;


    // !IMAGE HANDLE
    const [img, setImg] = useState('')
    const handleChangeImg = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImg(URL.createObjectURL(event.target.files[0]));
        }
    }

    return (
        <>

            <Container>


                <Card>
                    <CardHeader title="Add Price" />
                    <CardContent>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={3} >

                                    <Grid item sm={12} md={6} >
                                        <Field
                                            name="title"
                                            label="Title"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={6} >
                                        <Field
                                            name="description"
                                            label="Description"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={6} >
                                        <Field
                                            name="period"
                                            label="Period"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={6} >
                                        <Field
                                            name="price"
                                            label="Price"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>

                                    {/* <Grid item sm={12} md={6} >
                                        <FormControl fullWidth error={Boolean(touched.type && errors.type)} >
                                            <InputLabel id="type">Type</InputLabel>
                                            <Select
                                                labelId="type"
                                                id="Type"
                                                // value={role}
                                                label="type"
                                                // onChange={handleChange}
                                                {...getFieldProps('type')}
                                            >
                                                <MenuItem value={1}>Basic</MenuItem>
                                                <MenuItem value={2}>Standard</MenuItem>
                                                <MenuItem value={3}>Premium</MenuItem>
                                            </Select>
                                            {errors?.type ? <FormHelperText>{errors?.type}</FormHelperText> : null}
                                        </FormControl>
                                    </Grid> */}

                                    {/* <Grid item sm={12} md={6} ><TextField InputLabelProps={{ shrink: true }} name="licenceNumber" label="License Number" fullWidth {...getFieldProps('licenceNumber')} error={Boolean(touched.licenceNumber && errors.licenceNumber)} helperText={touched.licenceNumber && errors.licenceNumber} /></Grid> */}
                                    {/* <Grid item sm={12} md={6} > */}
                                    {/* <FieldArray name="features">
                                      {
                                        ({push,remove})=>(
                                            <div className='my-2' >
                                                {values.features.map((val,i)=>{
                                                   return(
                                                    <div className='d-flex my-2'>
                                                                
                                                       {values?.features?.length !== 1 &&    
                                                            <a onClick={() => remove(i)} className="btn btn-danger mr-2">
                                                                <i className="fa fa-trash"></i>
                                                            </a>
                                                        }
                                                        <input type='text' placeholder="Add Feature" className="form-control w-full" name={`features[${i}].val`} onChange={handleChange} onBlur={handleBlur}/>
                                                        <TextField InputLabelProps={{ shrink: true }} name="price" label="Price" fullWidth {...getFieldProps(`features[${i}].val`)} error={Boolean(touched.price && errors.price)} helperText={touched.price && errors.price} />
                                                        {i === values?.features.length - 1 && (
                                                            <>
                                                                <div className="col-4">
                                                                    <button
                                                                        className="add-address btn  btn-secondry"
                                                                        type="button"
                                                                        onClick={() =>
                                                                            push({
                                                                               val:""
                                                                            })
                                                                        }
                                                                    >
                                                                        <i className="ri-add-fill"></i> Add Member</button>
                                                                </div>
                                                            </>)}
                                                    </div>  
                                                   )
                                                })}
                                                
                                            </div>
                                        )
                                      }
                                    </FieldArray> */}
                                    {/* </Grid> */}
                                </Grid>

                                <Stack spacing={2} mt={3} direction="row" alignItems="center" justifyContent="flex-end">
                                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>Save</LoadingButton>
                                    {/* <Button type="button" variant="outlined" onClick={() => navigate(-1)}>
                                        Back
                                    </Button> */}
                                </Stack>
                            </Form>
                        </FormikProvider>
                    </CardContent>
                </Card>
            </Container>

            {/* <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={editHandler}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                </MenuItem>

                <MenuItem sx={{ color: 'error.main' }} onClick={deleteHandler}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem>
            </Popover> */}
        </>
    );
}

