import { useEffect, useState } from 'react';
// components
import { useLocation, useNavigate } from 'react-router-dom';

// @mui
import { Link as MLink, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { toast } from "react-toastify"
import { MuiOtpInput } from 'mui-one-time-password-input'
import LoadingOverlay from '../../components/shared/Backdrop';

// !utils
import { MESSAGE } from '../../utils/validationMessage';
import errorHandler from '../../utils/errorHandler';
import { NOSPACE_REGEX } from '../../utils/constants';

// !states
import apiAuth from '../../api/authService';


const validationSchema = Yup.object().shape({
    otp: Yup.string()
        .required(MESSAGE.REQUIRED)
        .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE)
        .min(4)
        .max(4)
});





export default function OtpPage() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const { state } = useLocation();

    const [otp, setOtp] = useState('')



    useEffect(() => {
        if (!state?.email) {
            toast.error("Please enter a email")
            navigate("/login");
        }
    }, [navigate, state?.email])


    const formik = useFormik({
        initialValues: { otp: '' },
        validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            apiAuth.verifyOtp({ email: state?.email, otp: values.otp })
                .then((res) => {
                    if (res.data.status === 1) {
                        toast.success(res?.data?.message)
                        navigate("/reset-password", { state: { otp: values?.otp, email: state?.email } })
                    }
                }).catch((err) => {
                    errorHandler(err)
                    setSubmitting(false)
                })
        },
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue, setSubmitting } = formik;

    const handleChange = (newValue) => {
        setOtp((prev) => {
            const data = newValue;
            setFieldValue('otp', data);
            return data;
        });
    }


    const resendOtp = () => {
        setIsLoading(true)
        apiAuth.resendOtp({ email: state?.email })
            .then((res) => {
                setIsLoading(false)
                toast.success(res?.data?.message)
            }).catch((err) => {
                setIsLoading(false)
                errorHandler(err)
            })
    }


    return (
        <>
            <LoadingOverlay isLoading={isLoading} />
            <FormikProvider value={formik}>
                <Typography variant="h3" sx={{ mb: 2 }} align="center">Verification</Typography>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        {/* <TextField name="otp" label="OTP" {...getFieldProps('otp')} error={Boolean(touched.otp && errors.otp)} helperText={touched.otp && errors.otp} /> */}
                        <MuiOtpInput value={otp} onChange={handleChange} />
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
                        <MLink variant="subtitle2" underline="hover" to='/forgot' onClick={resendOtp} sx={{ cursor: "pointer" }}>
                            Resend Otp?
                        </MLink>
                    </Stack>
                    <LoadingButton fullWidth size="large" type="submit" variant="contained" disabled={otp?.length !== 4}> Verify </LoadingButton>
                </Form>
            </FormikProvider>
        </>
    )
}
