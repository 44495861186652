import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify"
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Link as Mlink,
    Box
} from '@mui/material';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../../../sections/@dashboard/user';

import TableNotFound from '../../../components/shared/TableNotFound';


// !utils
import errorHandler from '../../../utils/errorHandler';
import routeConfig from '../../../utils/routeConfig';


// !states 
import restrosApi from "../../../api/restrosService"
import { getRestrosData, deleteRestro, changeStatus } from "../../../features/RestrosSlice"






const TABLE_HEAD = [
    { id: 'name', label: 'Owner Name', alignRight: false },
    { id: 'restaurantName', label: 'Restaurant Name', alignRight: false },
    { id: 'restaurantManagers', label: 'Restaurant Managers', alignRight: false },
    { id: 'purchasedPlan', label: 'Purchased Plan', alignRight: false },
    // { id: 'restro_detail.address', label: 'Address', alignRight: false },
    // { id: 'is_verified', label: 'Verified', alignRight: false },
    { id: "", label: 'Actions', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (orderBy === "is_verified") {
        console.log("is_verified", a[orderBy], b[orderBy])
        return 1;
    }


    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}


const center = { display: "inline-flex", justifyContent: "left" }


export default function RestroPage() {
    const { restroId } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()



    const [open, setOpen] = useState(null);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selectedId, setSelectedId] = useState('');
    const [index, setIndex] = useState(0)
    // const [filteredUsers, setFilteredUsers] = useState([])

    const [records, setRecords] = useState([]);
    const [data, setData] = useState([]);

    const { restros, totalRecords, currentPage, totalPages } = useSelector(state => state.restros)

    useEffect(() => {
        dispatch(getRestrosData({
            limit: rowsPerPage,
            page: page + 1,
            name: filterName,
        }))

    }, [page, rowsPerPage, filterName]);



    const refetchData = () => {
        dispatch(getRestrosData({
            limit: rowsPerPage,
            page: page + 1,
            name: filterName,
        }))
    }

    // useEffect(() => {
    //     setData(restros)
    //     setRecords(totalRecords)
    //     setPage(currentPage)
    // }, [restros])



    const [statusType, setStatusType] = useState('Active')
    const handleOpenMenu = (event, id, idx) => {
        setSelectedId(id)
        setOpen(event.currentTarget);
        setIndex(idx)
        setStatusType(restros[idx]?.status === 1 ? 'Inactive' : 'Active')
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = restros.map((n) => n._id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - restros.length) : 0;


    // const filteredUsers = //applySortFilter(restros, getComparator(order, orderBy), filterName)

    const isNotFound = !restros.length && !!filterName;


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };




    const deleteSelectedHandler = () => {
        console.log("delete selected")
    }


    // Delete Handler 

    const deleteHandler = async (e) => {
        setOpen(null);
        await restrosApi.delete({ id: selectedId })
            .then((res) => {
                dispatch(deleteRestro(selectedId))
                toast.success(res?.data?.message)
                refetchData()
            }).catch(err => {
                errorHandler(err)
            })
    }

    const statusHandler = async (data) => {
        setOpen(null);
        const status = restros[index]?.status === 1 ? 0 : 1;
        await restrosApi.changeStatus({
            id: selectedId,
            status
        })
            .then((res) => {
                toast.success(res.data.message)
                dispatch(changeStatus({ idx: index, status }))
            }).catch(err => {
                errorHandler(err)
            })
    }



    // const staffHandler = (e) => {
    //     navigate(`/dashboard/restro/${selectedId}/staff`)
    //     setOpen(null);
    // }

    const editHandler = (e) => {
        navigate(`/dashboard/restro/edit/${selectedId}`)
        setOpen(null);
    }


    const viewHandler = (e) => {
        navigate(`/dashboard/restro/${selectedId}`)
        setOpen(null);
    }



    return (
        <>
            {/* <Helmet>
                <title> Awin | Restaurant Management </title>
            </Helmet> */}

            {/* {filteredUsers ? filteredUsers?.length : null} */}
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h5" gutterBottom>
                        Restaurant Owner Management
                    </Typography>

                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} component={Link} to="/dashboard/restro/add">
                        New Restaurant Owner
                    </Button>

                </Stack>

                <Card>
                    <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} deleteSelectedHandler={deleteSelectedHandler} />
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    // order={order}
                                    // orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={restros.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {/* .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
                                    {restros && restros.length > 0 ? restros.map((row, idx) => {
                                        const { _id, name, email, phone, plan, restro_detail, rating, restaurantName, is_verified, is_subscribed, subscription_title, managers } = row;
                                        const selectedUser = selected.indexOf(_id) !== -1;

                                        return (
                                            <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                                                <TableCell align="left">
                                                    <Mlink component={Link} to={routeConfig.restro.owner.view(_id)}>{name}</Mlink>
                                                </TableCell>
                                                <TableCell>
                                                    {/* <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}> */}
                                                    <Box style={{ display: "inline-flex", flexDirection: "column" }}>
                                                        {restaurantName}
                                                        <Typography sx={{ ...center }} variant="subtitle2"  >
                                                            <Iconify icon={'ic:round-star'} color="#FFD700" />{rating}
                                                        </Typography>

                                                    </Box>
                                                    {/* </Box> */}
                                                    {/* <span style={{display:"flex",jus}}>
                                                        3.3
                                                        <Iconify icon={'ic:round-star'} />
                                                    </span> */}
                                                </TableCell>

                                                <TableCell component="th" scope="row">
                                                    {/* <Box sx={{ display: "flex", flexWrap: "wrap", width: "400px" }}> */}
                                                    {
                                                        managers?.length > 0 && managers[0]?.name !== undefined ? managers?.map((manager, index) => (
                                                            <span key={manager?._id}>
                                                                <Mlink component={Link} to={routeConfig.restro.manager.view(_id, manager._id)} sx={{ textTransform: "capitalize", mx: 0.3 }}>
                                                                    {manager.name}
                                                                </Mlink>
                                                                <br />
                                                                {/* {index !== managers.length - 1 ? ',' : ''} */}
                                                            </span>
                                                        )) : "-"
                                                    }
                                                    {/* </Box> */}
                                                </TableCell>
                                                {/* <TableCell align="left">
                                                    {managers?.length > 0 && managers[0]?.name !== undefined ? managers?.map((manager) => {
                                                        return <><Mlink component={Link} to={routeConfig.restro.manager.view(_id, manager._id)} >{manager.name}</Mlink>,</>
                                                    }) : "-"}
                                                </TableCell> */}
                                                <TableCell align="left">
                                                    {is_subscribed ? subscription_title : "No Purchased Plan"}
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, _id, idx)}>
                                                        <Iconify icon={'eva:more-vertical-fill'} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }) : (!isNotFound && <TableNotFound />)}
                                    {/* {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )} */}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Not found
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Try checking for typos or using complete words.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalRecords}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card >
            </Container >

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={editHandler}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                </MenuItem>
                {/* 
                <MenuItem onClick={staffHandler}>
                    <Iconify icon={'mdi:account'} sx={{ mr: 2 }} />
                    Staff
                </MenuItem> */}
                <MenuItem onClick={viewHandler}>
                    <Iconify icon={'tabler:view-360'} sx={{ mr: 2 }} />
                    View Detail
                </MenuItem>

                <MenuItem sx={{ color: statusType === "Active" ? '' : 'error.main' }} onClick={statusHandler}>
                    <Visibility status={statusType} />
                </MenuItem>

                <MenuItem sx={{ color: 'error.main' }} onClick={deleteHandler}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem>
            </Popover >
        </>
    );
}


const Visibility = ({ status }) => {
    if (status === "Active") {
        return (
            <>
                <Iconify icon={'teenyicons:tick-circle-solid'} sx={{ mr: 2 }} />
                Active
            </>
        )
    }

    return (
        <>
            <Iconify icon={'carbon:close-filled'} sx={{ mr: 2 }} />
            InActive
        </>
    )

}


