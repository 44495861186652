import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify"
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
} from '@mui/material';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../../../sections/@dashboard/user';



import TableNotFound from '../../../components/shared/TableNotFound';


// !uils
import errorHandler from '../../../utils/errorHandler';


// !states 
import paymentsApi from "../../../api/paymentService"
import { getPaymentsData, deletePayment, changeStatus } from "../../../features/PaymentSlice"


const TABLE_HEAD = [
    { id: 'restaurantName', label: 'Restaurant Name', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'phone', label: 'Phone', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'Received Amount', label: 'Received Amount', alignRight: false },
    // { id: 'isVerified', label: 'Verified', alignRight: false },
    { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function PaymentPage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()



    const [open, setOpen] = useState(null);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedId, setSelectedId] = useState('');
    // const [filteredUsers, setFilteredUsers] = useState([])



    const { payments, totalRecords } = useSelector(state => state.payments)

    useEffect(() => {
        dispatch(getPaymentsData({
            limit: 10,
            page,
            // name,
            // email,
        }))

    }, [page]);

    useEffect(() => {
        console.log(payments)
    }, [payments])

    const handleOpenMenu = (event, id) => {
        setSelectedId(id)
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = payments.map((n) => n._id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - payments.length) : 0;


    const filteredUsers = applySortFilter(payments, getComparator(order, orderBy), filterName)

    const isNotFound = !filteredUsers.length && !!filterName;




    // Delete Handler 

    const deleteHandler = async (e) => {
        await paymentsApi.delete({ restaurantId: selectedId })
            .then(() => {
                dispatch(deletePayment(selectedId))
            }).catch(err => {
                errorHandler(err)
            })
        setOpen(null);
    }

    const statusHandler = async (data) => {
        await paymentsApi.verified(data)
            .then((res) => {
                toast.success(res.data.message)
                dispatch(changeStatus(data))
            }).catch(err => {
                errorHandler(err)
            })
    }

    const viewHandler = (e) => {
        console.log("ndsfhdkit: view")
        navigate(`/dashboard/payment/view/${selectedId}`)
        setOpen(null);
    }


    return (
        <>
            {/* <Helmet>
                <title> Restaurant Management </title>
            </Helmet> */}
            {/* {JSON.stringify(users)} */}
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Payment Management
                    </Typography>
                    {/* <Link to="/dashboard/payment/add">
                        <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
                            New Payment
                        </Button>
                    </Link> */}
                </Stack>

                <Card>
                    <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={payments.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {filteredUsers?.length ? filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        const { _id, name, email, phone, restro_detail, subscription_detail } = row;
                                        const selectedUser = selected.indexOf(_id) !== -1;

                                        return (
                                            <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                                                {/* <TableCell padding="checkbox">
                                                    <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, _id)} />
                                                </TableCell> */}

                                                <TableCell component="th" scope="row">
                                                    {/* <Typography variant="subtitle2" noWrap> */}

                                                    {restro_detail?.restaurantName}
                                                    {/* </Typography> */}
                                                </TableCell>

                                                {/* <TableCell align="left">{company}</TableCell> */}

                                                <TableCell align="left">{name}</TableCell>
                                                <TableCell align="left">{phone}</TableCell>
                                                <TableCell align="left">{email}</TableCell>
                                                <TableCell align="left">$ {subscription_detail.price}</TableCell>

                                                <TableCell align="right" >
                                                    <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, _id)}>
                                                        <Iconify icon={'eva:more-vertical-fill'} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }) : (!isNotFound && <TableNotFound />)}

                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}

                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Not found
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Try checking for typos or using complete words.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    {/* <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalRecords}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
                </Card >
            </Container >

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={viewHandler}>
                    <Iconify icon={'tabler:view-360'} sx={{ mr: 2 }} />
                    View Detail
                </MenuItem>

                {/* <MenuItem sx={{ color: 'error.main' }} onClick={deleteHandler}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem> */}
            </Popover >
        </>
    );
}



