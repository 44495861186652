import AxiosInterceptor from "../utils/AxiosInterceptor";

const RestroService = {
    // add: (value) => AxiosInterceptor().post(`/admin/restro/add`, value),
    // delete: (value) => AxiosInterceptor().post(`/admin/restro/delete`, value),
    // edit: (value) => AxiosInterceptor().post(`/admin/restro/edit`, value),
    // getAll: (values) => AxiosInterceptor().post(`/admin/restro/list`, values),
    // getById: (values) => AxiosInterceptor().post(`/admin/restro/detail`, values),
    // changeStatus: (values) => AxiosInterceptor().post(`/admin/restro/change-status`, values),

    // owner
    add: (value) => AxiosInterceptor().post(`/admin/restro/add-owner`, value),
    edit: (value) => AxiosInterceptor().post(`/admin/restro/edit-owner`, value),
    getAll: (values) => AxiosInterceptor().post(`/admin/restro/owner-list`, values),
    delete: (value) => AxiosInterceptor().post(`/admin/restro/delete-owner`, value),
    getById: (values) => AxiosInterceptor().post(`/admin/restro/owner-detail`, values),
    changeStatus: (values) => AxiosInterceptor().post(`/admin/restro/owner-change-status`, values),



    // Manager
    addManager: (value) => AxiosInterceptor().post(`/admin/restro/add-manager`, value),
    editManager: (value) => AxiosInterceptor().post(`/admin/restro/edit-manager`, value),
    deleteManager: (value) => AxiosInterceptor().post(`/admin/restro/delete-manager`, value),
    getManagerById: (values) => AxiosInterceptor().post(`/admin/restro/manager-detail`, values),


    // staff
    addStaff: (value) => AxiosInterceptor().post(`/admin/restro/add-staff`, value),
    editStaff: (value) => AxiosInterceptor().post(`/admin/restro/edit-staff`, value),
    deleteStaffMember: (value) => AxiosInterceptor().post(`/admin/restro/delete-staff`, value),
    getStaffById: (values) => AxiosInterceptor().post(`/admin/restro/manager-detail`, values),
    getAllStaff: (values) => AxiosInterceptor().post(`/admin/restro/staff-list`, values),
    getStaffMemberDetail: (values) => AxiosInterceptor().post(`/admin/restro/staff-detail`, values),
    changeStaffStatus: (values) => AxiosInterceptor().post(`/admin/restro/change-staff-status`, values)
    // verified: (values) => AxiosInterceptor().post(`/admin/restro/accept-reject-request`, values),
};

export default RestroService; 
