import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify"
// @mui
import {
    Card,
    Stack,
    Button,
    Container,
    TextField,
    Link as MLink,
    Grid,
    CardContent,
    CardHeader,

    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Divider,
    Box
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate, useParams } from 'react-router-dom';


import Field from '../../../components/Inputs/Field';
import MultiField from '../../../components/Inputs/MultiField';

// !utils
import { MOBILE_REGEX, NOSPACE_REGEX } from '../../../utils/constants';
import { complexEmailRegex } from '../../../utils/emailCheck';
import { MESSAGE } from '../../../utils/validationMessage';
import errorHandler from '../../../utils/errorHandler';


// !states 
import subscriptionApi from "../../../api/subscriptionService"
import { getById } from "../../../features/SubscriptionSlice"




const validationSchema = Yup
    .object()
    .shape({
        title: Yup.string().strict(true).max(30, "First name should be less than 30 chars").required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
        description: Yup.string().required(MESSAGE.REQUIRED).max(255),
        price: Yup.string().required(MESSAGE.REQUIRED),
        // type: Yup.string().required(MESSAGE.REQUIRED),
        period: Yup.string().required(MESSAGE.REQUIRED),
        // features: Yup.array().min(1).of(Yup.object().shape({
        //     val: Yup.string().trim().required('Feature can\'t be empty')
        // }))
    });



export default function SubscriptionEditPage() {
    const { id } = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { subscription } = useSelector(state => state.subscriptions)

    useEffect(() => {
        dispatch(getById({ id }))
    }, [id])

    const initialValues = {
        title: "",
        description: "",
        type: "",
        price: "",
        period: "",
        features: [{ val: "" }]
    };


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const data = values?.features.map((feature) => feature.val)
                const res = await subscriptionApi.edit({ ...values, features: data });

                toast.success(res?.data?.message)
                navigate("/dashboard/price")
            } catch (err) {
                errorHandler(err);
                setSubmitting(false);
            }
        },
    });

    const { errors, touched, isSubmitting, handleSubmit, resetForm, getFieldProps, values } = formik;


    useEffect(() => {
        if (!subscription) return
        const { title, description, price, period, _id, type, features } = subscription

        const temp = {
            title,
            description,
            price,
            id: _id,
            type,
            features: [],// features && features?.length > 0 && features[0]?.val !== "" ? features?.map((feature) => ({ val: feature })) : [{ val: "" }],
            // status:status,
            period
        }
        console.log(temp)
        resetForm({ values: { ...temp } });
    }, [subscription])

    const refFunc = useRef()

    return (
        <>
            <Container>
                <Card>
                    <CardHeader title="Edit Price" />
                    <CardContent>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={3} >
                                    <Grid item sm={12} md={6} >
                                        <Field
                                            name="title"
                                            label="Title"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={6} >
                                        <Field
                                            name="description"
                                            label="Description"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={6} >
                                        <Field
                                            name="period"
                                            label="Period"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={6} >
                                        <Field
                                            name="price"
                                            label="Price"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>
                                </Grid>
                                {/* <Box my={4} /> */}
                                {/* <Grid item sm={12} md={6}  >
                                    <Button
                                        // size="large"
                                        className="add-address btn  btn-secondry"
                                        type="button"
                                        // fullWidth
                                        variant="contained"
                                        color="success"
                                        sx={{ color: 'white' }}
                                        // disabled={errors?.features?.length}
                                        onClick={() =>
                                            refFunc.current({
                                                val: ""
                                            })
                                        }
                                    >
                                        Add Features
                                    </Button>
                                </Grid> */}


                                {/* <MultiField
                                    name="features"
                                    label="Features"
                                    valKey="val"

                                    values={values}
                                    touched={touched}
                                    errors={errors}
                                    getFieldProps={getFieldProps}
                                    refFunc={refFunc}
                                /> */}

                                <Stack spacing={2} mt={3} direction="row" alignItems="center" justifyContent="flex-end">
                                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>Edit</LoadingButton>
                                    {/* <Button type="button" variant="outlined" onClick={() => navigate(-1)}>
                                        Back
                                    </Button> */}
                                </Stack>
                            </Form>
                        </FormikProvider>
                    </CardContent>
                </Card>
            </Container>
        </>
    );
}




