import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify"
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    CardContent,
} from '@mui/material';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../../../sections/@dashboard/user';
// mock
// import USERLIST from '../../_mock/user';



// !utils
import errorHandler from '../../../utils/errorHandler';
import { API_BASE } from '../../../utils/constants';

// !states 
import staffApi from "../../../api/staffService"
import { getStaffData, deleteStaff, changeStatus } from "../../../features/StaffSlice"
import { getById } from "../../../features/RestrosSlice"



const TABLE_HEAD = [
    // { id: 'restaurantName', label: 'Restaurant Name', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'phone', label: 'Phone', alignRight: false },
    { id: 'address', label: 'Address', alignRight: false },
    { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function RestroPage() {
    const { restroId } = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate()

    console.log(restroId)

    const [open, setOpen] = useState(null);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [selectedId, setSelectedId] = useState('');
    const [selectedBranchId, setSelectedBranchId] = useState('');

    // const [filteredUsers, setFilteredUsers] = useState([])

    // const { restro } = useSelector(state => state.restros)
    // useEffect(() => {
    //     dispatch(getById({ restaurantId: restroId }))
    // }, [restroId])


    const { staffs, totalRecords } = useSelector(state => state.staffs)

    useEffect(() => {
        dispatch(getStaffData({ restro_id: restroId }))
    }, [page]);



    const handleOpenMenu = (event, id, branchId) => {
        setSelectedId(id)
        setSelectedBranchId(branchId)
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = staffs.map((n) => n._id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - staffs.length) : 0;


    const filteredUsers = applySortFilter(staffs, getComparator(order, orderBy), filterName)

    const isNotFound = !filteredUsers.length && !!filterName;




    // Delete Handler 

    const deleteHandler = async (e) => {
        console.log({ restaurantId: restroId, id: selectedId })
        await staffApi.delete({ restro_id: restroId, id: selectedId })
            .then(() => {
                dispatch(deleteStaff(selectedId))
                console.log('staff deleted', { id: selectedId })
            }).catch(err => {
                errorHandler(err)
            })
        setOpen(null);
    }

    const statusHandler = async (data) => {
        await staffApi.verified(data)
            .then((res) => {
                toast.success(res.data.message)
                dispatch(changeStatus(data))
            }).catch(err => {
                errorHandler(err)
            })
    }



    const staffHandler = (e, name) => {
        navigate(`/dashboard/restro/:restroId/staff`)
        setOpen(null);
    }

    const editHandler = (e) => {
        navigate(`/dashboard/restro/${restroId}/staff/edit/${selectedId}?branchId=${selectedBranchId}`)
        setOpen(null);
    }


    return (
        <>
            {/* <Helmet>
                <title> Table Master | Staff Management </title>
            </Helmet> */}
            {/* {JSON.stringify(users)} */}
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {staffs.length > 0 ? staffs[0].restaurantName : null} Staff
                    </Typography>
                    {/* <Link  style={{ textDecoration: "none" }}> */}
                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} component={Link} to={`/dashboard/restro/${restroId}/staff/add`}>
                        New Staff
                    </Button>
                    {/* </Link> */}
                </Stack>

                <Card>

                    <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    // order={order}
                                    // orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={staffApi.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {filteredUsers && filteredUsers.length > 0 ? filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        const { _id, user_detail, name, email, phone, restro_detail, is_verified, branch_detail } = row ?? {};
                                        const selectedUser = selected.indexOf(_id) !== -1;

                                        return (
                                            <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                                                {/* <TableCell padding="checkbox">
                                                    <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, _id)} />
                                                </TableCell> */}



                                                <TableCell align="left">{user_detail?.name}</TableCell>
                                                <TableCell align="left">{user_detail?.email}</TableCell>
                                                <TableCell align="left">{user_detail?.phone}</TableCell>
                                                <TableCell align="left">{branch_detail?.address}</TableCell>
                                                {/* <TableCell align="left">{}</TableCell> */}

                                                <TableCell align="right">
                                                    <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, user_detail?._id, branch_detail?._id)}>
                                                        <Iconify icon={'eva:more-vertical-fill'} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }) : (
                                        !isNotFound && (<TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                            <Paper
                                                sx={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <Typography variant="body2">
                                                    No results found

                                                </Typography>
                                            </Paper>
                                        </TableCell>)
                                    )}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Not found
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Try checking for typos or using complete words.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    {/* <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalRecords}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={editHandler}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                </MenuItem>


                <MenuItem sx={{ color: 'error.main' }} onClick={deleteHandler}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem>
            </Popover >
        </>
    );
}



const Verified = ({ isVerified, id, statusHandler }) => {
    if (isVerified === 1) return <Typography color="green">Accepted</Typography>

    if (isVerified === 2) return <Typography color="error">Rejected</Typography>

    return (
        <>
            <Button color="success" variant="contained" sx={{ mr: 2, color: "#fff" }} onClick={() => {
                statusHandler({
                    id,
                    status: 1
                })
            }}>Accept</Button>
            <Button color="error" variant="contained" onClick={() => {
                statusHandler({
                    id,
                    status: 2
                })
            }}>Reject</Button>
        </>
    )
}