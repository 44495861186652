import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify"
// @mui
import {
    Card,
    Stack,
    Button,
    Container,
    TextField,
    Link as MLink,
    Grid,
    CardContent,
    CardHeader,
    IconButton,
    Box,
    Typography,
    Avatar
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate, useParams } from 'react-router-dom';




import Iconify from '../../../components/iconify/Iconify';
// !utils
import errorHandler from '../../../utils/errorHandler';
import { MOBILE_REGEX, NOSPACE_REGEX } from '../../../utils/constants';
import { MESSAGE } from '../../../utils/validationMessage';
import { complexEmailRegex } from '../../../utils/emailCheck';

// !states 
import paymentApi from "../../../api/paymentService"
import { getById } from "../../../features/PaymentSlice"



export default function RestroPage() {
    const { id } = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { payment } = useSelector(state => state.payments)

    useEffect(() => {
        dispatch(getById({ id }))
    }, [id])
    return (
        <>
            <Container>
                <Card>
                    <CardHeader title={<>
                        <Box sx={{
                            display: 'flex',
                            // justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <IconButton sx={{ mr: 1 }} onClick={() => navigate(-1)}>
                                <Iconify icon="ep:back" />
                            </IconButton>
                            View Detail
                        </Box>
                    </>} />
                    {/* </CardHeader> */}

                    <CardContent>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}>
                            <Avatar alt="Super Admin Profile Picture" sx={{ width: 150, height: 150 }} >
                                {payment?.name}
                            </Avatar>
                            <Typography variant="h3" sx={{ mt: 1 }}>{payment?.restro_detail?.restaurantName}</Typography>
                        </Box>
                        <Typography color="textSecondary">Name: {payment?.name}</Typography>
                        <Typography color="textSecondary">Email: {payment?.email}</Typography>
                        <Typography color="textSecondary">Phone: {payment?.phone}</Typography>
                        <Typography color="textSecondary">Subscription: {payment?.subscription_detail?.title}</Typography>
                        <Typography color="textSecondary">Price: ${payment?.subscription_detail?.price}</Typography>
                    </CardContent>
                </Card>
            </Container >
        </>
    );
}

