import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify"
// @mui
import {
    Card,
    Stack,
    Avatar,
    Button,
    Container,
    Typography,
    TextField,
    Link as MLink,
    Icon,
    Grid,
    CardContent,
    CardHeader,
    InputAdornment,
    IconButton,

    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';


import BackButton from '../../../components/Buttons/BackButton';
// !selectField
import Field from '../../../components/Inputs/Field';
import SelectField from '../../../components/Inputs/SelectField';
import DateField from '../../../components/Inputs/DateField';
import ChipSelect from '../../../components/Inputs/ChipSelect';


// !utils
import errorHandler from '../../../utils/errorHandler';
import { MOBILE_REGEX, NOSPACE_REGEX, PASSWORDS_REGEX } from '../../../utils/constants';
import { MESSAGE } from '../../../utils/validationMessage';
import { complexEmailRegex } from '../../../utils/emailCheck';
import routeConfig from '../../../utils/routeConfig';

// !states 
import restroApi from "../../../api/restrosService"
import { getById } from "../../../features/StaffSlice"
import { getById as getRestroId, getRestroStaffMemberById } from "../../../features/RestrosSlice"




const validationSchema = Yup.object().shape({
    name: Yup.string().strict(true).max(30, "First name should be less than 30 chars").required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
    email: Yup.string()
        .required(MESSAGE.REQUIRED)
        .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE)
        .test("is-email", MESSAGE.EMAIL, (val) => complexEmailRegex(val))
        .max(255),
    phone: Yup.string().required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE).matches(MOBILE_REGEX, MESSAGE.PHONE),
    role: Yup.string().required('Role is required'),
    rights: Yup.string().strict(true).max(255, "Rights is required").required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
    shift: Yup.string().required("Shift is required")
});




export default function StaffEditPage() {
    const { state } = useLocation();
    const navigate = useNavigate()
    const { restroId, managerId, staffMemberId } = useParams()
    const [searchParams] = useSearchParams();


    const [showPassword, setShowPassword] = useState(false);


    const dispatch = useDispatch()
    const { restroStaffMember } = useSelector(state => state.restros)


    useEffect(() => {
        dispatch(getRestroStaffMemberById({
            manager_id: managerId,
            owner_id: restroId,
            id: staffMemberId
        }))
    }, [restroId, managerId])



    const initialValues = {
        name: "",
        email: "",
        phone: "",
        role: "",
        rights: "Pending",
        shift: "",
        assignedDate: [],
        owner_id: restroId,
        manager_id: managerId
    };


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const response = await restroApi.editStaff(values);
                toast.success(response?.data?.message)
                navigate(routeConfig.restro.manager.view(restroId, managerId), { state: { value: state?.value } })
            } catch (err) {
                errorHandler(err);
                setSubmitting(false);
            }
        },
    });

    const { errors, touched, isSubmitting, handleSubmit, resetForm, getFieldProps, values, setFieldValue } = formik;


    useEffect(() => {
        if (!restroStaffMember) return
        const { name, _id, staff_detail, shift, email, phone, user_detail, branch_detail, assignedDate } = restroStaffMember
        resetForm({
            values: {
                name: user_detail?.name,
                email: user_detail?.email,
                phone: user_detail?.phone,
                role: user_detail?.role,
                rights: "Pending",
                shift,
                assignedDate: [],// assignedDate && assignedDate?.length ? assignedDate.map((d) => new Date(d)) : [],
                owner_id: restroId,
                manager_id: managerId,
                id: user_detail?._id,
            }
        });
    }, [restroStaffMember])

    const [role, setRole] = useState('');

    const handleChange = (event) => {
        setRole(event.target.value);
        // setFieldValue('role', event.target.value);
    };


    const [openDatePicker, setOpenDatePicker] = useState()
    const assignedDateHandler = (dates) => {
        console.log(dates)
        setFieldValue('assignedDate', dates)
    }

    const handleDelete = (idx) => {
        const arr = values.assignedDate;
        arr.splice(idx, 1)
        setFieldValue('assignedDate', arr)
    }

    return (
        <>

            <Container>
                <Card>
                    <CardHeader title={<BackButton text="Edit Staff" variant="h6" />} />
                    <CardContent>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={3} >
                                    <Grid item sm={12} md={6} >
                                        <Field
                                            name="name"
                                            label="Name"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={6} >
                                        <Field
                                            name="email"
                                            label="Email address"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={6} >
                                        <Field
                                            name="phone"
                                            label="Phone"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>

                                    <Grid item sm={12} md={6} >
                                        <FormControl fullWidth error={Boolean(touched.role && errors.role)} >
                                            <InputLabel id="Role">Role</InputLabel>
                                            <Select
                                                labelId="Role"
                                                id="Role"
                                                label="Role"
                                                // onChange={handleChange}
                                                {...getFieldProps('role')}
                                            >
                                                <MenuItem value={4}>Manager</MenuItem>
                                                <MenuItem value={5}>Waiter</MenuItem>
                                                <MenuItem value={6}>Driver</MenuItem>
                                                <MenuItem value={7}>Kitchen</MenuItem>
                                                <MenuItem value={8}>Cashier</MenuItem>
                                            </Select>
                                            {errors?.role ? <FormHelperText>{errors?.role}</FormHelperText> : null}
                                        </FormControl>
                                    </Grid>



                                    <Grid item sm={12} md={6} >
                                        <FormControl fullWidth  >
                                            <InputLabel id="shift">Shift</InputLabel>
                                            <Select
                                                labelId="shift"
                                                id="shift"
                                                // value={role}
                                                label="shift"
                                                // onChange={handleChange}
                                                {...getFieldProps('shift')}
                                            >
                                                <MenuItem value={`Morning`}>Morning</MenuItem>
                                                <MenuItem value={`Evening`}>Evening</MenuItem>
                                                <MenuItem value={`Night`}>Night</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>


                                    {/* <Grid item xs={12} sm={12} md={6} >
                                        <ChipSelect selectedDates={values.assignedDate} setOpen={setOpenDatePicker} handleDelete={handleDelete} />
                                      
                                        <DateField selectedDates={values.assignedDate} setSelectedDates={assignedDateHandler} open={openDatePicker} setOpen={setOpenDatePicker} />
                                    </Grid> */}
                                </Grid>
                                <Stack spacing={2} mt={3} direction="row" alignItems="center" justifyContent="flex-end">
                                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>Edit</LoadingButton>
                                    {/* <Button type="button" variant="outlined" onClick={() => navigate(-1)}>
                                        Back
                                    </Button> */}
                                </Stack>
                            </Form>
                        </FormikProvider>
                    </CardContent>
                </Card>
            </Container>
        </>
    );
}

