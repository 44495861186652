import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify"

// @mui
import {
    Card,
    Stack,
    Avatar,
    Button,
    Container,
    Typography,
    TextField,
    Link as MLink,
    Icon,
    Grid,
    CardContent,
    CardHeader,
    InputAdornment,
    IconButton,

    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    OutlinedInput
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';

import { useFormik, Form, FormikProvider, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Iconify from '../../../components/iconify';


import Field from '../../../components/Inputs/Field';
import SelectField from '../../../components/Inputs/SelectField';
import PasswordField from '../../../components/Inputs/PasswordField';
import DateField from '../../../components/Inputs/DateField';
import ChipSelect from '../../../components/Inputs/ChipSelect';
import BackButton from '../../../components/Buttons/BackButton';


// !utils
import errorHandler from '../../../utils/errorHandler';
import { MOBILE_REGEX, NOSPACE_REGEX, PASSWORDS_REGEX } from '../../../utils/constants';
import { MESSAGE } from '../../../utils/validationMessage';
import { complexEmailRegex } from '../../../utils/emailCheck';
import routeConfig from '../../../utils/routeConfig';

// !states 
import { getById } from "../../../features/RestrosSlice"
import staffApi from "../../../api/staffService"
import restroApi from '../../../api/restrosService';





const validationSchema = Yup.object().shape({
    name: Yup.string().strict(true).max(30, "First name should be less than 30 chars").required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
    email: Yup.string()
        .required(MESSAGE.REQUIRED)
        .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE)
        .test("is-email", MESSAGE.EMAIL, (val) => complexEmailRegex(val))
        .max(255),
    password: Yup.string().required(MESSAGE.REQUIRED).matches(PASSWORDS_REGEX, MESSAGE.PASSWORD),
    phone: Yup.string().required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE).matches(MOBILE_REGEX, MESSAGE.PHONE),
    role: Yup.string().required('Role is required'),
    rights: Yup.string().strict(true).max(255, "Rights is required").required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
    shift: Yup.string().required("Shift is required"),
    // assignedTable: Yup.array().of(Yup.string()).required("Table is required").min(1, "Select at least one Table"),
    // assignedTable: Yup.array().when('role', {
    //     is: 5, // Condition: Run this validation when role is '7'
    //     then: Yup.array().of(Yup.string()).required("Table is required").min(1, "Select at least one Table"),
    //     otherwise: Yup.array(), // No validation when role is not '7'
    // }),
    // assignedDate: Yup.array().when('role', {
    //     is: 5,
    //     then: Yup.array().of(Yup.date().typeError('Invalid date format. Use a valid date.')).required('Dates are required').min(1, 'Select at least one date'),
    //     otherwise: Yup.array(),
    // })
});



function randomArray() {
    const minLength = 1; // T1
    const maxLength = 9; // T9
    const length = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;

    const resultArray = [];
    for (let i = 0; i < length; i += 1) {
        resultArray.push(`T${i + 1}`);
    }

    return resultArray;
}


function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function RestroPage() {
    const { restroId, managerId } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()


    const theme = useTheme();

    const [tables, setTables] = useState([])

    const initialValues = {
        name: "",
        email: "",
        password: "",
        phone: "",
        role: "",
        rights: "Pending",
        shift: "",
        assignedDate: [],
        assignedTable: [],
        owner_id: restroId,
        manager_id: managerId
    };


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                let assignedDate = []
                let assignedTable = []
                if (values?.role !== 7) {
                    // values.assignedDate = []
                    assignedDate = []
                    assignedTable = []
                } else {
                    assignedDate = values.assignedDate
                    assignedTable = values.assignedTable
                }

                const response = await restroApi.addStaff({ ...values, assignedDate, assignedTable });
                toast.success(response?.data?.message)
                navigate(routeConfig.restro.manager.view(restroId, managerId), { replace: true })
            } catch (err) {
                errorHandler(err);
                setSubmitting(false);
            }
        },
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue, values } = formik;



    const [role, setRole] = useState('');

    const handleChange = (event) => {
        setRole(event.target.value);
        setFieldValue('role', event.target.value);
    };


    const [personName, setPersonName] = useState([]);
    const names = [
        'T1', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'T8', 'T9',
    ];
    const handleChangeSelect = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const [openDatePicker, setOpenDatePicker] = useState()
    const assignedDateHandler = (dates) => {
        console.log(dates)
        setFieldValue('assignedDate', dates)
    }

    const handleDelete = (idx) => {
        const arr = values.assignedDate;
        arr.splice(idx, 1)
        setFieldValue('assignedDate', arr)
    }

    return (
        <>
            {/* {JSON.stringify(errors)} */}
            <Container>

                <Card>
                    <CardHeader title={<BackButton text="Add Staff" variant="h6" />} />
                    <CardContent>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={3} >
                                    <Grid item xs={12} sm={12} md={6} >
                                        <Field
                                            name="name"
                                            label="Name"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>


                                    <Grid item xs={12} sm={12} md={6} >
                                        <Field
                                            name="email"
                                            label="Email address"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} >
                                        <Field
                                            name="phone"
                                            label="Phone"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>



                                    <Grid item xs={12} sm={12} md={6} >
                                        <PasswordField
                                            name="password"
                                            label="Password"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} >
                                        <FormControl fullWidth error={Boolean(touched.role && errors.role)}>
                                            <InputLabel id="Role">Role</InputLabel>
                                            <Select
                                                labelId="Role"
                                                id="Role"
                                                value={role}
                                                label="Role"
                                                // onChange={handleChange}
                                                {...getFieldProps('role')}
                                            >
                                                {/* <MenuItem value={4}>Manager</MenuItem> */}
                                                <MenuItem value={5}>Waiter</MenuItem>
                                                {/* <MenuItem value={6}>Driver</MenuItem> */}
                                                <MenuItem value={7}>Kitchen</MenuItem>
                                                <MenuItem value={8}>Cashier</MenuItem>
                                            </Select>

                                            <FormHelperText> <ErrorMessage name="role" /></FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    {/* {JSON.stringify(values)}
                                    {JSON.stringify(errors)} */}



                                    <Grid item xs={12} sm={12} md={6} >
                                        <FormControl fullWidth error={Boolean(touched.shift && errors.shift)}>
                                            <InputLabel id="shift">Shift</InputLabel>
                                            <Select
                                                labelId="shift"
                                                id="shift"
                                                // value={role}
                                                label="shift"
                                                // onChange={handleChange}
                                                {...getFieldProps('shift')}
                                            >
                                                <MenuItem value={`Morning`}>Morning</MenuItem>
                                                <MenuItem value={`Evening`}>Evening</MenuItem>
                                                <MenuItem value={`Night`}>Night</MenuItem>
                                            </Select>
                                            <FormHelperText> <ErrorMessage name="shift" /></FormHelperText>
                                        </FormControl>
                                    </Grid>


                                    {values.role === 5 ? (<Grid item xs={12} sm={12} md={6} >
                                        <FormControl fullWidth error={Boolean(touched.assignedTable && errors.assignedTable)}>
                                            <InputLabel id="assignedTable">Assign Table</InputLabel>
                                            <Select
                                                fullWidth
                                                multiple
                                                displayEmpty
                                                labelId="assignedTable"
                                                label="Assign Table"
                                                value={personName}
                                                onChange={handleChangeSelect}
                                                MenuProps={MenuProps}
                                                inputProps={{ 'aria-label': 'Assign Table' }}
                                                {...getFieldProps('assignedTable')}
                                            >
                                                <MenuItem disabled value="">
                                                    Assign Table
                                                </MenuItem>
                                                {names.map((name) => (
                                                    <MenuItem
                                                        key={name}
                                                        value={name}
                                                        style={getStyles(name, personName, theme)}
                                                    >
                                                        {name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText><ErrorMessage name="assignedTable" /></FormHelperText>
                                        </FormControl>
                                    </Grid>) : null}
                                    {/* {values.role === 5 ? <Grid item xs={12} sm={12} md={6} >
                                        <ChipSelect selectedDates={values.assignedDate} errors={errors} touched={touched} setOpen={setOpenDatePicker} handleDelete={handleDelete} />
                                     
                                        <DateField selectedDates={values.assignedDate} setSelectedDates={assignedDateHandler} open={openDatePicker} setOpen={setOpenDatePicker} />
                                    </Grid> : null} */}
                                </Grid>
                                <Stack spacing={2} mt={3} direction="row" alignItems="center" justifyContent="flex-end">
                                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>Add </LoadingButton>
                                    {/* <Button type="button" variant="outlined" onClick={() => navigate(-1)}>
                                        Back
                                    </Button> */}
                                </Stack>
                            </Form>
                        </FormikProvider>
                    </CardContent>
                </Card>
            </Container>
        </>
    );
}

