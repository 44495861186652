import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify"
// @mui
import {
    Card,
    Stack,
    Avatar,
    Button,
    Container,
    Typography,
    TextField,
    Link as MLink,
    Icon,
    Grid,
    CardContent,
    CardHeader,
    InputAdornment,
    IconButton
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../../../sections/@dashboard/user';


// !utils
import errorHandler from '../../../utils/errorHandler';
import { API_BASE, MOBILE_REGEX, NOSPACE_REGEX, PASSWORDS_REGEX } from '../../../utils/constants';
import { MESSAGE } from '../../../utils/validationMessage';
import { complexEmailRegex } from '../../../utils/emailCheck';


// !states 
import restrosApi from "../../../api/restrosService"
import { getRestrosData, deleteRestro, changeStatus } from "../../../features/RestrosSlice"




const validationSchema = Yup.object().shape({
    name: Yup.string().strict(true).max(30, "First name should be less than 30 chars").required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
    email: Yup.string()
        .required(MESSAGE.REQUIRED)
        .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE)
        .test("is-email", MESSAGE.EMAIL, (val) => complexEmailRegex(val))
        .max(255),
    password: Yup.string().required(MESSAGE.REQUIRED).matches(PASSWORDS_REGEX, MESSAGE.PASSWORD),
    phone: Yup.string().required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE).matches(MOBILE_REGEX, MESSAGE.PHONE),
    restaurantName: Yup.string().strict(true).max(30, "First name should be less than 30 chars").required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
    address: Yup.string().strict(true).max(255, "Street address should be less than 255 chars").required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
    licenceNumber: Yup.string().matches(/^[a-zA-Z0-9]*$/, 'License number must be alphanumeric').required('This field is required'),
});



export default function RestroPage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { restros } = useSelector(state => state.restros)

    const [showPassword, setShowPassword] = useState(false);
    const initialValues = {
        name: "",
        email: "",
        password: "",
        phone: "",
        subscription: "",
        restaurantName: "",
        address: "",
        licenceNumber: "",
    };


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const response = await restrosApi.add(values);
                toast.success(response?.data?.message)
                navigate("/dashboard/restro")
            } catch (err) {
                errorHandler(err);
                setSubmitting(false);
            }
        },
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;


    // !IMAGE HANDLE
    const [img, setImg] = useState('')
    const handleChangeImg = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImg(URL.createObjectURL(event.target.files[0]));
        }
    }

    return (
        <>

            <Container>
                <Card>
                    <CardHeader title="Add Payment" />
                    <CardContent>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={3} >
                                    {/* <Grid item sm={12} md={12} >
                                        <Stack mb={2} spacing={3} alignItems="center" justifyContent="center">
                                            {img ? <Avatar alt="Super Admin Profile Picture" src={img} sx={{ width: 150, height: 150 }} /> : null}
                                            <Button
                                                variant="contained"
                                                component="label"
                                                startIcon={<Icon icon="uil:image-upload" />}
                                            >
                                                Change Image
                                                <input
                                                    type="file"
                                                    hidden
                                                    accept="image/*"
                                                    onChange={(e) => {
                                                        handleChangeImg(e);
                                                        formik.setFieldValue("profilepic", e.target.files[0]);
                                                    }}
                                                />
                                            </Button>
                                        </Stack>
                                    </Grid> */}
                                    <Grid item sm={12} md={6} ><TextField name="name" label="Name" fullWidth {...getFieldProps('name')} error={Boolean(touched.name && errors.name)} helperText={touched.name && errors.name} /></Grid>
                                    <Grid item sm={12} md={6} ><TextField name="email" label="Email address" fullWidth {...getFieldProps('email')} error={Boolean(touched.email && errors.email)} helperText={touched.email && errors.email} /></Grid>
                                    <Grid item sm={12} md={6} ><TextField name="phone" label="Phone" fullWidth {...getFieldProps('phone')} error={Boolean(touched.phone && errors.phone)} helperText={touched.phone && errors.phone} /></Grid>
                                    <Grid item sm={12} md={6} >
                                        <TextField
                                            fullWidth
                                            name="password"
                                            label="Password"
                                            type={showPassword ? 'text' : 'password'}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            {...getFieldProps('password')}
                                            error={Boolean(touched.password && errors.password)}
                                            helperText={touched.password && errors.password}
                                        />

                                    </Grid>
                                    <Grid item sm={12} md={6} ><TextField name="restaurantName" label="Restaurant Name" fullWidth {...getFieldProps('restaurantName')} error={Boolean(touched.restaurantName && errors.restaurantName)} helperText={touched.restaurantName && errors.restaurantName} /></Grid>
                                    <Grid item sm={12} md={6} ><TextField name="address" label="Address" fullWidth {...getFieldProps('address')} error={Boolean(touched.address && errors.address)} helperText={touched.address && errors.address} /></Grid>
                                    <Grid item sm={12} md={6} ><TextField name="licenceNumber" label="Licence Number" fullWidth {...getFieldProps('licenceNumber')} error={Boolean(touched.licenceNumber && errors.licenceNumber)} helperText={touched.licenceNumber && errors.licenceNumber} /></Grid>

                                </Grid>
                                <Stack spacing={2} mt={3} direction="row" alignItems="center" justifyContent="flex-end">
                                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>Add </LoadingButton>
                                    <Button type="button" variant="outlined" onClick={() => navigate(-1)}>
                                        Back
                                    </Button>
                                </Stack>
                            </Form>
                        </FormikProvider>
                    </CardContent>
                </Card>
            </Container>
        </>
    );
}

