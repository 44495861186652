import axios from 'axios';
import { API_BASE } from '../utils/constants';

const API_URL = API_BASE + 'admin/auth/';

const apiAuth = {
  login: (value) => axios.post(API_URL + 'login', value),

  forgot: (value) => axios.post(API_URL + 'forgot-password', value),

  reset: (value) => axios.post(API_URL + 'reset-password', value),

  verifyOtp: (value) => axios.post(API_URL + 'verify-otp', value),

  resendOtp: (value) => axios.post(API_URL + 'resend-otp', value),


  resetPassword: (value) => axios.post(API_URL + 'reset-password', value),
};

export default apiAuth;
