import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify"
// @mui
import {
    Card,
    Stack,
    Avatar,
    Button,
    Container,
    Typography,
    TextField,
    Link as MLink,
    Icon,
    Grid,
    CardContent,
    CardHeader,
    IconButton,
    Box
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate, useParams } from 'react-router-dom';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../../../sections/@dashboard/user';




import BackButton from '../../../components/Buttons/BackButton';
import Field from '../../../components/Inputs/Field';
import MultiField from '../../../components/Inputs/MultiField';


// !utils
import errorHandler from '../../../utils/errorHandler';
import { API_BASE, MOBILE_REGEX, NOSPACE_REGEX, PASSWORDS_REGEX } from '../../../utils/constants';
import { MESSAGE } from '../../../utils/validationMessage';
import { complexEmailRegex } from '../../../utils/emailCheck';
import routeConfig from '../../../utils/routeConfig';

// !states
import restrosApi from "../../../api/restrosService"
import { getRestrosData, deleteRestro, changeStatus } from "../../../features/RestrosSlice"
import PasswordField from '../../../components/Inputs/PasswordField';



const validationSchema = Yup.object().shape({
    name: Yup.string().strict(true).max(30, "First name should be less than 30 chars").required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
    email: Yup.string()
        .required(MESSAGE.REQUIRED)
        .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE)
        .test("is-email", MESSAGE.EMAIL, (val) => complexEmailRegex(val))
        .max(255),
    password: Yup.string().required(MESSAGE.REQUIRED).matches(PASSWORDS_REGEX, MESSAGE.PASSWORD),
    phone: Yup.string().required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE).matches(MOBILE_REGEX, MESSAGE.PHONE),
    address: Yup.string().strict(true).max(100, "Address should be less than 100 chars").required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE)
});



export default function RestroManagerAddPage() {
    const { restroId } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { restro } = useSelector(state => state.restros)
    const arrayRef = useRef()
    const [showPassword, setShowPassword] = useState(false);
    const initialValues = {
        name: "",
        email: "",
        password: "",
        phone: "",
        address: "",
        owner_id: restroId
    };


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const response = await restrosApi.addManager(values);
                toast.success(response?.data?.message)
                navigate(routeConfig.restro.owner.view(restroId))
            } catch (err) {
                errorHandler(err);
                setSubmitting(false);
            }
        },
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, values } = formik;


    // !IMAGE HANDLE
    const [img, setImg] = useState('')
    const handleChangeImg = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImg(URL.createObjectURL(event.target.files[0]));
        }
    }

    return (
        <>
            <Container>
                <Card>
                    <CardHeader title={<BackButton text="Add Manager" variant="h6" />} />
                    <CardContent>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={3} >
                                    <Grid item sm={12} md={6} >
                                        <Field
                                            name="name"
                                            label="Name"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={6} >
                                        <Field
                                            name="email"
                                            label="Email"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={6} >
                                        <Field
                                            name="phone"
                                            label="Phone"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>

                                    <Grid item sm={12} md={6} >
                                        <PasswordField
                                            name="password"
                                            label="Password"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>

                                    <Grid item sm={12} md={6} >
                                        <Field
                                            name="address"
                                            label="Address"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>

                                </Grid>
                                <Stack spacing={2} mt={3} direction="row" alignItems="center" justifyContent="flex-end">
                                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>Add </LoadingButton>
                                    {/* <Button type="button" variant="outlined" onClick={() => navigate(-1)}>
                                        Back
                                    </Button> */}
                                </Stack>
                            </Form>
                        </FormikProvider>
                    </CardContent>
                </Card>
            </Container>
        </>
    );
}

