import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify"
// @mui
import {
    Card,
    Stack,
    Button,
    Container,
    TextField,
    Link as MLink,
    Grid,
    CardContent,
    CardHeader,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate, useParams } from 'react-router-dom';



// !utils
import errorHandler from '../../../utils/errorHandler';
import { MOBILE_REGEX, NOSPACE_REGEX } from '../../../utils/constants';
import { MESSAGE } from '../../../utils/validationMessage';
import { complexEmailRegex } from '../../../utils/emailCheck';


// !states 
import paymentApi from "../../../api/paymentService"
import { getById } from "../../../features/PaymentSlice"




const validationSchema = Yup.object().shape({
    name: Yup.string().strict(true).max(30, "First name should be less than 30 chars").required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
    email: Yup.string()
        .required(MESSAGE.REQUIRED)
        .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE)
        .test("is-email", MESSAGE.EMAIL, (val) => complexEmailRegex(val))
        .max(255),
    // password: Yup.string().required(MESSAGE.REQUIRED).matches(PASSWORDS_REGEX, MESSAGE.PASSWORD),
    phone: Yup.string().required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE).matches(MOBILE_REGEX, MESSAGE.PHONE).max(10, "Phone should be 10 charcter long"),

    restaurantName: Yup.string().strict(true).max(30, "First name should be less than 30 chars").required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
    address: Yup.string().strict(true).max(255, "Street address should be less than 255 chars").required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
    licenceNumber: Yup.string().matches(/^[a-zA-Z0-9]*$/, 'License number must be alphanumeric').required('This field is required'),
});



export default function RestroPage() {
    const { id } = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { restro } = useSelector(state => state.restros)

    useEffect(() => {
        dispatch(getById({ restaurantId: id }))
    }, [id])

    const initialValues = {
        restaurantName: "",
        address: "",
        name: ""
    };


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const response = await paymentApi.edit(values);
                toast.success(response?.data?.message)
                navigate("/dashboard/payment")
            } catch (err) {
                errorHandler(err);
                setSubmitting(false);
            }
        },
    });

    const { errors, touched, isSubmitting, handleSubmit, resetForm, getFieldProps } = formik;


    useEffect(() => {
        if (!restro) return
        const { name, _id, restaurantName, address, email, phone } = restro
        console.log(restro)
        resetForm({
            values: {
                name,
                restaurantName,
                address,
                email,
                phone,
                id: _id
            }
        });
    }, [restro])


    return (
        <>
            {/* {JSON.stringify(users)} */}
            <Container>
                <Card>
                    <CardHeader title="Edit Payment" />
                    <CardContent>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={3} >
                                    <Grid item sm={12} md={6} >
                                        <TextField InputLabelProps={{ shrink: true }} name="restaurantName" label="Restaurant Name" fullWidth {...getFieldProps('restaurantName')} error={Boolean(touched.restaurantName && errors.restaurantName)} helperText={touched.restaurantName && errors.restaurantName} />
                                    </Grid>
                                    <Grid item sm={12} md={6} >
                                        <TextField InputLabelProps={{ shrink: true }} name="name" label="Name" fullWidth {...getFieldProps('name')} error={Boolean(touched.name && errors.name)} helperText={touched.name && errors.name} />
                                    </Grid>
                                    <Grid item sm={12} md={6} ><TextField InputLabelProps={{ shrink: true }} name="email" label="Email" fullWidth {...getFieldProps('email')} error={Boolean(touched.email && errors.email)} helperText={touched.email && errors.email} /></Grid>
                                    <Grid item sm={12} md={6} ><TextField InputLabelProps={{ shrink: true }} name="phone" label="Phone" fullWidth {...getFieldProps('phone')} error={Boolean(touched.phone && errors.phone)} helperText={touched.phone && errors.phone} /></Grid>
                                    <Grid item sm={12} md={6} ><TextField InputLabelProps={{ shrink: true }} name="address" label="Address" fullWidth {...getFieldProps('address')} error={Boolean(touched.address && errors.address)} helperText={touched.address && errors.address} /></Grid>
                                    <Grid item sm={12} md={6} ><TextField InputLabelProps={{ shrink: true }} name="licenceNumber" label="License Number" fullWidth {...getFieldProps('licenceNumber')} error={Boolean(touched.licenceNumber && errors.licenceNumber)} helperText={touched.licenceNumber && errors.licenceNumber} /></Grid>
                                </Grid>
                                <Stack spacing={2} mt={3} direction="row" alignItems="center" justifyContent="flex-end">
                                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>Edit</LoadingButton>
                                    <Button type="button" variant="outlined" onClick={() => navigate(-1)}>
                                        Back
                                    </Button>
                                </Stack>
                            </Form>
                        </FormikProvider>
                    </CardContent>
                </Card>
            </Container>
        </>
    );
}

