import { useSelector } from 'react-redux';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';

import UserPage from './pages/dashboard/UserPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';



// !Auth 
import LoginPage from './pages/auth/LoginPage';
import ForgetForm from './pages/auth/ForgetPage';
import OtpPage from './pages/auth/OtpPage';
import ResetPassword from './pages/auth/ResetPasswordPage';


// !RESTRO
import RestroPage from './pages/dashboard/restro/RestroPage';
import RestroAddPage from './pages/dashboard/restro/RestroAddPage';
import RestroEditPage from './pages/dashboard/restro/RestroEditPage';
import StaffPage from './pages/dashboard/restro/StaffPage';
import StaffAddPage from './pages/dashboard/restro/StaffAddPage';
import StaffEditPage from './pages/dashboard/restro/StaffEditPage';


// !Payment
import PaymentPage from './pages/dashboard/payment/PaymentPage';
import PaymentAddPage from './pages/dashboard/payment/PaymentAddPage';
import PaymentViewPage from './pages/dashboard/payment/PaymentViewPage';
import PaymentEditPage from './pages/dashboard/payment/PaymentEditPage';



// !Price
import SubscriptionPage from './pages/dashboard/subscription/SubscriptionPage';
import SubscriptionAddPage from './pages/dashboard/subscription/SubscriptionAddPage';
import SubscriptionEditPage from './pages/dashboard/subscription/SubscriptionEditPage';
import RestroOwnerPage from './pages/dashboard/restro/RestroOwnerPage';
import RestroManagerAddPage from './pages/dashboard/restro/RestroManagerAddPage';
import RestroManagerPage from './pages/dashboard/restro/RestroManagerPage';
import RestroOwnerViewPage from './pages/dashboard/restro/RestroOwnerViewPage';
import RestroManagerEditPage from './pages/dashboard/restro/RestroManagerEditPage';
import RestroManagerViewPage from './pages/dashboard/restro/RestroManagerViewPage';

export default function Router() {
  const isLoggedIn = useSelector((state) => state.auth.token);

  const PrivateRoutes = [
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      errorElement: <Page404 />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        {
          path: 'restro', children: [
            { index: true, element: <RestroPage /> },
            { path: 'add', element: <RestroAddPage /> },
            { path: 'edit/:id', element: <RestroEditPage /> },
            { path: ':restroId', element: <RestroOwnerViewPage /> },
            { path: ':restroId/owner', element: <RestroOwnerPage /> },


            { path: ':restroId/manager/:managerId', element: <RestroManagerPage /> },
            // { path: ':restroId/manager/:managerId/view', element: <RestroManagerViewPage /> },
            { path: ':restroId/manager/add', element: <RestroManagerAddPage /> }, // !Manager Added by Owner on owner page
            { path: ':restroId/manager/:managerId/edit', element: <RestroManagerEditPage /> },

            { path: ':restroId/staff', element: <StaffPage /> },
            { path: ':restroId/manager/:managerId/staff/add', element: <StaffAddPage /> },
            { path: ':restroId/manager/:managerId/staff/:staffMemberId/edit', element: <StaffEditPage /> }
          ]
        },
        {
          path: 'payment', children: [
            { index: true, element: <PaymentPage /> },
            { path: 'add', element: <PaymentAddPage /> },
            { path: 'view/:id', element: <PaymentViewPage /> },
            { path: 'edit/:id', element: <PaymentEditPage /> },
          ]
        },
        {
          path: 'price', children: [
            { index: true, element: <SubscriptionPage /> },
            { path: 'add', element: <SubscriptionAddPage /> },
            { path: 'edit/:id', element: <SubscriptionEditPage /> },
          ]
        },
      ],
    },
    { path: '*', element: <Navigate to="/dashboard" /> },
  ];

  const PublicRoutes = [
    {
      element: <SimpleLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: 'login', element: <LoginPage /> },
        { path: '/forgot', element: <ForgetForm /> },
        { path: '/otp', element: <OtpPage /> },
        { path: '/reset-password', element: <ResetPassword /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/login" replace />,
    },

  ];

  const routes = useRoutes(isLoggedIn ? PrivateRoutes : PublicRoutes);

  return routes;
}
