import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify"
// @mui
import {
    Card,
    Stack,
    Button,
    Container,
    TextField,
    Link as MLink,
    Grid,
    CardContent,
    CardHeader,
    Box,
    Divider,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate, useParams } from 'react-router-dom';




import Field from '../../../components/Inputs/Field';
import BackButton from '../../../components/Buttons/BackButton';

// !utils
import errorHandler from '../../../utils/errorHandler';
import { MOBILE_REGEX, NOSPACE_REGEX, PASSWORDS_REGEX } from '../../../utils/constants';
import { MESSAGE } from '../../../utils/validationMessage';
import { complexEmailRegex } from '../../../utils/emailCheck';
import routeConfig from '../../../utils/routeConfig';



// !states 
import restrosApi from "../../../api/restrosService"
import { getManagerById } from "../../../features/RestrosSlice"



const validationSchema = Yup.object().shape({
    name: Yup.string().strict(true).max(30, "First name should be less than 30 chars").required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
    email: Yup.string()
        .required(MESSAGE.REQUIRED)
        .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE)
        .test("is-email", MESSAGE.EMAIL, (val) => complexEmailRegex(val))
        .max(255),
    phone: Yup.string().required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE).matches(MOBILE_REGEX, MESSAGE.PHONE),
    address: Yup.string().strict(true).max(100, "Address should be less than 100 chars").required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE)
});


export default function RestroPage() {
    const { restroId, managerId } = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const arrayRef = useRef()



    const [branches, setBranches] = useState([])

    const { restroManager } = useSelector(state => state.restros)
    useEffect(() => {
        dispatch(getManagerById({ owner_id: restroId, id: managerId }))
    }, [restroId])

    const initialValues = {
        name: "",
        email: "",
        password: "",
        phone: "",
        address: "",
        id: managerId,
        owner_id: restroId,
    };


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const response = await restrosApi.editManager({ ...values });
                toast.success(response?.data?.message)
                navigate(routeConfig.restro.owner.view(restroId), { replace: true })
            } catch (err) {
                errorHandler(err);
                setSubmitting(false);
            }
        },
    });

    const { errors, touched, isSubmitting, handleSubmit, resetForm, getFieldProps, values } = formik;


    useEffect(() => {
        if (!restroManager) return
        const { name, _id, address, email, phone, user_detail } = restroManager

        resetForm({
            values: {
                name: user_detail?.name,
                address,
                email: user_detail?.email,
                phone: user_detail?.phone,
                id: managerId,
                owner_id: restroId,
            }
        });
    }, [restroManager])



    const removeBranchHandler = (id) => {
        if (id) {
            setBranches([...branches, id])
        }
    }

    return (
        <>
            <Container>
                <Card>
                    <CardHeader title={<BackButton text={`Edit ${restroManager?.restro_detail?.restaurantName}'s Restaurant Manager`} variant="h6" />} />
                    <CardContent>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                {/* <input type='hidden' {...getFieldProps("id")} value={id} /> */}
                                <Grid container spacing={3} >

                                    <Grid item sm={12} md={6} >
                                        <Field
                                            name="name"
                                            label="Name"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>

                                    <Grid item sm={12} md={6} >
                                        <Field
                                            name="email"
                                            label="Email"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>

                                    <Grid item sm={12} md={6} >
                                        <Field
                                            name="phone"
                                            label="Phone"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>

                                    <Grid item sm={12} md={6} >
                                        <Field
                                            name="address"
                                            label="Address"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>


                                </Grid>

                                <Stack spacing={2} mt={3} direction="row" alignItems="center" justifyContent="flex-end">
                                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>Edit</LoadingButton>
                                    {/* <Button type="button" variant="outlined" onClick={() => navigate(-1)}>
                                        Back
                                    </Button> */}
                                </Stack>
                            </Form>
                        </FormikProvider>
                    </CardContent>
                </Card>
            </Container>
        </>
    );
}

