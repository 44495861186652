import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify"
// @mui
import {
    Card,
    Stack,
    Button,
    Container,
    TextField,
    Link as MLink,
    Grid,
    CardContent,
    CardHeader,
    Box,
    Divider,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate, useParams } from 'react-router-dom';



import Field from '../../../components/Inputs/Field';
import BackButton from '../../../components/Buttons/BackButton';

// !utils
import errorHandler from '../../../utils/errorHandler';
import { MOBILE_REGEX, NOSPACE_REGEX } from '../../../utils/constants';
import { MESSAGE } from '../../../utils/validationMessage';
import { complexEmailRegex } from '../../../utils/emailCheck';



// !states 
import restrosApi from "../../../api/restrosService"
import { getById } from "../../../features/RestrosSlice"




const validationSchema = Yup.object().shape({
    name: Yup.string().strict(true).max(30, "First name should be less than 30 chars").required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
    email: Yup.string()
        .required(MESSAGE.REQUIRED)
        .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE)
        .test("is-email", MESSAGE.EMAIL, (val) => complexEmailRegex(val))
        .max(255),
    // password: Yup.string().required(MESSAGE.REQUIRED).matches(PASSWORDS_REGEX, MESSAGE.PASSWORD),
    phone: Yup.string().required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE).matches(MOBILE_REGEX, MESSAGE.PHONE).max(10, "Phone should be 10 charcter long"),

    restaurantName: Yup.string().strict(true).max(30, "First name should be less than 30 chars").required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
    // address: Yup.string().strict(true).max(255, "Street address should be less than 255 chars").required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
    licenceNumber: Yup.string().matches(/^[a-zA-Z0-9]*$/, 'License number must be alphanumeric').required('This field is required'),
    addresses: Yup.array().min(1).of(Yup.object().shape({
        address: Yup.string().trim().required('Address can\'t be empty')
    }))
});



export default function RestroPage() {
    const { id, staffId } = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const arrayRef = useRef()



    const [branches, setBranches] = useState([])

    const { restro } = useSelector(state => state.restros)
    useEffect(() => {
        dispatch(getById({ id }))
    }, [id])

    const initialValues = {
        restaurantName: "",
        name: "",
        email: "",
        phone: "",
        licenceNumber: "",
        // addresses: [{ address: "" }],
        id: ""
    };


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                // console.log(values)
                // const addresses = values.addresses.map((el) => el._id ? false : { address: el.address }).filter(Boolean)
                const response = await restrosApi.edit({ ...values });

                toast.success(response?.data?.message)
                navigate("/dashboard/restro", { replace: true })
            } catch (err) {
                errorHandler(err);
                setSubmitting(false);
            }
        },
    });

    const { errors, touched, isSubmitting, handleSubmit, resetForm, getFieldProps, values } = formik;


    useEffect(() => {
        if (!restro) return
        const { name, _id, restro_detail, licenceNumber, restaurantName, address, email, phone, branches } = restro

        resetForm({
            values: {
                name,
                restaurantName,
                address,
                email,
                phone,
                licenceNumber,
                // addresses: branches,
                id
            }
        });
    }, [restro])



    const removeBranchHandler = (id) => {
        if (id) {
            setBranches([...branches, id])
        }
    }

    return (
        <>
            <Container>
                <Card>
                    <CardHeader title={<BackButton text="Edit Restaurant Owner" variant="h6" />} />
                    <CardContent>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={3} >
                                    <Grid item sm={12} md={6} >
                                        <Field
                                            name="restaurantName"
                                            label="Restaurant Name"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>

                                    <Grid item sm={12} md={6} >
                                        <Field
                                            name="name"
                                            label="Name"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>

                                    <Grid item sm={12} md={6} >
                                        <Field
                                            name="email"
                                            label="Email"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>

                                    <Grid item sm={12} md={6} >
                                        <Field
                                            name="phone"
                                            label="Phone"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>

                                    <Grid item sm={12} md={6} >
                                        <Field
                                            name="licenceNumber"
                                            label="License Number"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>
                                </Grid>

                                <Box my={4} />

                                {/* <Grid item sm={12} md={6} >
                                    <Button
                                        // size="large"
                                        className="add-address btn  btn-secondry"
                                        type="button"
                                        // fullWidth
                                        // disabled={errors?.addresses?.length}
                                        variant="contained"
                                        color="success"
                                        sx={{ color: 'white' }}
                                        onClick={() =>
                                            arrayRef.current({
                                                address: ""
                                            })
                                        }
                                    >
                                        Add Branch
                                    </Button>
                                </Grid> */}


                                {/* {values.addresses ? <MultiField
                                    name="addresses"
                                    label="Address"
                                    valKey="address"
                                    removeBranchHandler={removeBranchHandler}


                                    refFunc={arrayRef}
                                    values={values}
                                    touched={touched}
                                    errors={errors}
                                    getFieldProps={getFieldProps}
                                /> : null} */}



                                <Stack spacing={2} mt={3} direction="row" alignItems="center" justifyContent="flex-end">
                                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>Edit</LoadingButton>
                                    {/* <Button type="button" variant="outlined" onClick={() => navigate(-1)}>
                                        Back
                                    </Button> */}
                                </Stack>
                            </Form>
                        </FormikProvider>
                    </CardContent>
                </Card>
            </Container>
        </>
    );
}

