import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify"
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Link as Mlink,
    Grid,
    CardContent,
    Box,
    Divider
} from '@mui/material';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../../../sections/@dashboard/user';

import TableNotFound from '../../../components/shared/TableNotFound';


// !utils
import errorHandler from '../../../utils/errorHandler';
import BackButton from '../../../components/Buttons/BackButton';
import routeConfig from '../../../utils/routeConfig';

// !states 
import restrosApi from "../../../api/restrosService"
import { getRestrosData, deleteRestro, changeStatus, getById } from "../../../features/RestrosSlice"



const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'address', label: 'Address', alignRight: false },
    { id: "", label: 'Actions' },
];


function descendingComparator(a, b, orderBy) {
    if (orderBy === "is_verified") {
        console.log("is_verified", a[orderBy], b[orderBy])
        return 1;
    }


    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export default function OwnerPage() {
    const { restroId, managerId } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()


    const { restros, totalRecords, currentPage, totalPages, restro } = useSelector(state => state.restros)
    useEffect(() => {
        dispatch(getById({ id: restroId }))
    }, [restroId])


    const refetch = () => {
        dispatch(getById({ id: restroId }))
    }



    const [open, setOpen] = useState(null);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selectedId, setSelectedId] = useState('');



    const handleOpenMenu = (event, id) => {
        setSelectedId(id)
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = restros.map((n) => n._id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - restros.length) : 0;


    // const filteredUsers = //applySortFilter(restros, getComparator(order, orderBy), filterName)

    const isNotFound = !restros.length && !!filterName;




    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };




    const deleteSelectedHandler = () => {
        console.log("delete selected")
    }


    // Delete Handler 

    const deleteHandler = async (e) => {
        await restrosApi.deleteManager({ owner_id: restroId, id: selectedId })
            .then((res) => {
                refetch()
                toast.success(res?.data?.message)
            }).catch(err => {
                errorHandler(err)
            })
        setOpen(null);
    }



    const viewHandler = () => {
        navigate(routeConfig.restro.manager.view(restroId, selectedId))
    }



    const editHandler = (e) => {
        navigate(routeConfig.restro.owner.editManager(restroId, selectedId))
        setOpen(null);
    }


    return (
        <>
            {/* <Helmet>
                <title> Awin | Restaurant Management </title>
            </Helmet> */}
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <BackButton text={`${restro?.restaurantName}'s Owner Management`} link={'/dashboard/restro'} />

                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} component={Link} to={routeConfig.restro.owner.addManager(restroId)}>
                        Add Managers
                    </Button>

                </Stack>

                <Card>
                    <CardContent>
                        <Paper elevation={3} sx={{ my: 2, py: 2 }}>
                            <Typography variant="h5" sx={{ mx: 2 }}>{restro?.restaurantName}'s Owner Detail :</Typography>
                            <Divider sx={{ borderStyle: "dotted" }} />
                            <Box sx={{ mt: 2, mx: 2 }}>
                                <Grid container spacing={3}>
                                    <Grid item md={6} lg={3} xs={12} >
                                        <Typography variant="subtitle2" sx={{ textTransform: "capitalize" }} >Name:     {restro?.name}</Typography>
                                    </Grid>
                                    <Grid item md={6} lg={3} xs={12}>
                                        <Typography variant="subtitle2" >Email:  {restro?.email}</Typography>
                                    </Grid>

                                    <Grid item md={6} lg={3} xs={12}>
                                        <Typography variant="subtitle2" >Phone: {restro?.phone}</Typography>
                                    </Grid>

                                    <Grid item md={6} lg={3} xs={12}>
                                        <Typography variant="subtitle2" >License No. : {restro?.licenceNumber}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </CardContent>


                    <Divider sx={{ borderStyle: "dotted" }} />
                    <Paper sx={{ mt: 3, mb: 1, px: 2 }}>
                        <Typography variant="h5"  >Managers Management</Typography>
                    </Paper>

                    {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} deleteSelectedHandler={deleteSelectedHandler} /> */}
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    // order={order}
                                    // orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={restros.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {/* .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
                                    {restro?.managers?.length > 0 && restro?.managers[0]?.name ? restro?.managers?.map((row) => {
                                        const { _id, name, email, phone, restro_detail, restaurantName, is_verified, address } = row;
                                        const selectedUser = selected.indexOf(_id) !== -1;

                                        return (
                                            <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                                                <TableCell align="left">
                                                    <Mlink component={Link} to={routeConfig.restro.manager.view(restroId, _id)}>{name}</Mlink>
                                                </TableCell>
                                                <TableCell align="left">{email}</TableCell>
                                                <TableCell align="left">{address}</TableCell>

                                                <TableCell align="left" >
                                                    <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, _id)}>
                                                        <Iconify icon={'eva:more-vertical-fill'} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }) : (!isNotFound && <TableNotFound />)}
                                    {/* {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )} */}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Not found
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Try checking for typos or using complete words.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                    {/* 
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalRecords}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
                </Card >
            </Container >

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={editHandler} >
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                </MenuItem>

                <MenuItem onClick={viewHandler} >
                    <Iconify icon={'tabler:view-360'} sx={{ mr: 2 }} />
                    View Detail
                </MenuItem>

                <MenuItem sx={{ color: 'error.main' }} onClick={deleteHandler} >
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem>
            </Popover >
        </>
    );
}



