import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import subscriptionsService from "../api/subscriptionService";


export const getSubscriptionsData = createAsyncThunk("subscriptions/getSubscriptionsData", async (value) => {
    const { data } = await subscriptionsService.getAll(value);
    return data.response
});

export const getById = createAsyncThunk("subscriptions/getById", async (value) => {
    const { data } = await subscriptionsService.getById(value);
    return data.response
});



const initialState = {
    subscriptions: [],
    subscription: {}
};

const updateSubscriptions = (state, { payload }) => {
    state.subscriptions = payload;
}

const selectedSubscriptions = (state, { payload }) => {
    state.subscription = payload;
}

export const subscriptionsSlice = createSlice({
    name: "subscriptions",
    initialState,
    reducers: {
        getSubscription: (state, { payload }) => {
            state.subscriptions = payload
        },
        deleteSubscription: (state, { payload }) => {
            state.subscriptions = state.subscriptions.filter(user => user._id !== payload)
        },
        changeStatus: (state, { payload }) => {
            const { id, status } = payload;
            const userIndex = state.subscriptions.findIndex((user) => user._id === id);

            if (userIndex !== -1) {
                const updatedUsers = [...state.subscriptions];
                updatedUsers[userIndex] = {
                    ...updatedUsers[userIndex],
                    status,
                };

                return {
                    ...state,
                    subscriptions: updatedUsers,
                };
            }

            return state;
        },

        editUser: (state, action) => {

        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSubscriptionsData.fulfilled, updateSubscriptions)
            .addCase(getById.fulfilled, selectedSubscriptions);
    },
});

export const { getSubscription, deleteSubscription, editUser, changeStatus } = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;
