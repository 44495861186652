import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import staffApi from "../api/staffService";

export const getStaffData = createAsyncThunk("staff/getStaffData", async (value) => {
    const { data } = await staffApi.getAll(value);
    console.log(data)
    return data.response
});

export const getById = createAsyncThunk("staff/getById", async (value) => {
    const { data } = await staffApi.getById(value);
    return data.response
});



const initialState = {
    staffs: [],
    currentPage: 0,
    totalPages: 0,
    totalRecords: 0,
    visitedPage: [],
    staff: {},
};

const updateRestros = (state, { payload }) => {
    state.staffs = payload;
};


const selectedRestro = (state, { payload }) => {
    state.staff = payload;
}

export const staffSlice = createSlice({
    name: "staff",
    initialState,
    reducers: {
        getStaff: (state, { payload }) => {
            state.staffs = payload
        },
        deleteStaff: (state, { payload }) => {
            state.staffs = state.staffs.filter(user => user._id !== payload)
        },

        // editStaff: (state, action) => {

        // },
    },
    extraReducers: (builder) => {
        builder.addCase(getStaffData.fulfilled, updateRestros)
            .addCase(getById.fulfilled, selectedRestro);
    },
});

export const { getStaff, deleteStaff, editStaff, changeStatus } = staffSlice.actions;

export default staffSlice.reducer;
