import AxiosInterceptor from "../utils/AxiosInterceptor";

const staffApi = {
    add: (value) => AxiosInterceptor().post(`/admin/staff/add`, value),
    edit: (value) => AxiosInterceptor().post(`/admin/staff/edit`, value),
    delete: (value) => AxiosInterceptor().post(`/admin/staff/delete`, value),
    getAll: (values) => AxiosInterceptor().post(`/admin/staff/list`, values),
    getById: (values) => AxiosInterceptor().post(`/admin/staff/detail`, values),
    verified: (values) => AxiosInterceptor().post(`/admin/staff/accept-reject-request`, values),
    changeStatus: (values) => AxiosInterceptor().post(`/admin/staff/change-status`, values),
};

export default staffApi; 
