import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify"
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Link as Mlink,
    CardContent,
    Grid,
    Box,


    Tabs,
    Tab,
    TableHead,
    Divider
} from '@mui/material';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../../../sections/@dashboard/user';
import StaffTabs from '../../../sections/manager/StaffTabs';

import BackButton from '../../../components/Buttons/BackButton';

// !utils
import errorHandler from '../../../utils/errorHandler';
import routeConfig from '../../../utils/routeConfig';


// !states 
import restrosApi from "../../../api/restrosService"
import { getRestrosData, deleteRestro, changeStatus, getManagerById } from "../../../features/RestrosSlice"


export default function RestroManagerPage() {
    const { restroId, managerId } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [selectedId, setSelectedId] = useState('');


    const { restroManager } = useSelector(state => state.restros)


    useEffect(() => {
        dispatch(getManagerById({
            owner_id: restroId,
            id: managerId
        }))
    }, [restroId])


    const deleteHandler = async (e) => {
        await restrosApi.delete({ id: selectedId })
            .then(() => {
                dispatch(deleteRestro(selectedId))
            }).catch(err => {
                errorHandler(err)
            })
    }



    const statusHandler = async (data) => {
        await restrosApi.verified(data)
            .then((res) => {
                toast.success(res.data.message)
                dispatch(changeStatus(data))
            }).catch(err => {
                errorHandler(err)
            })
    }



    const editHandler = (e) => {
        navigate(`/dashboard/restro/edit/${selectedId}`)
    }


    return (
        <>
            {/* <Helmet>
                <title>Table Master | Restaurant Management </title>
            </Helmet> */}
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <BackButton text={`${restroManager?.restro_detail?.restaurantName}'s Manager Management`} />
                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} component={Link} to={routeConfig.restro.manager.addStaff(restroId, managerId)} >
                        Add Staff
                    </Button>
                </Stack>


                <Card>
                    <CardContent>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography variant="h5" gutterBottom sx={{ transform: "capitalise" }}>
                                {restroManager?.restro_detail?.restaurantName}
                            </Typography>
                            <Typography variant="h5" gutterBottom>
                                {restroManager?.address}
                            </Typography>
                        </Box>

                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="h5" sx={{ mr: 1 }}>Ratings :</Typography>
                            {
                                [...Array(5)].map((_, idx) => (
                                    <Iconify icon={'ic:round-star'} key={idx} color="#FFD700" />
                                ))
                            }
                            <Typography sx={{ ml: 0.2 }} variant="subtitle2">(115)</Typography>
                        </Box>
                    </CardContent>


                    <Divider sx={{ borderStyle: "dotted" }} />
                    <Paper sx={{ mt: 3, mb: 2, px: 2 }}>
                        <Typography variant="h5">{restroManager?.user_detail?.name}'s Staff Management</Typography>
                    </Paper>

                    <StaffTabs />
                </Card >
            </Container >
        </>
    );
}

