import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import RestrosService from "../api/restrosService";

export const getRestrosData = createAsyncThunk("restros/getRestrosData", async (value) => {
    const { data } = await RestrosService.getAll(value);
    return data.response
});

export const getById = createAsyncThunk("restros/getById", async (value) => {
    const { data } = await RestrosService.getById(value);
    return data.response
});


export const getManagerById = createAsyncThunk("restros/getManagerById", async (value) => {
    const { data } = await RestrosService.getManagerById(value);
    return data.response
});


export const getRestroStaffData = createAsyncThunk("restros/getRestroStaffData", async (value) => {
    const { data } = await RestrosService.getAllStaff(value);
    return data.response
});

export const getRestroStaffById = createAsyncThunk("restros/getRestroStaffById", async (value) => {
    const { data } = await RestrosService.getStaffById(value);
    return data.response
});


export const getRestroStaffMemberById = createAsyncThunk("restros/getRestroStaffMemberById", async (value) => {
    const { data } = await RestrosService.getStaffMemberDetail(value);
    return data.response
});




const initialState = {
    restros: [],
    currentPage: 0,
    totalPages: 0,
    totalRecords: 0,
    visitedPage: [],
    restro: {},

    restroManagers: [],
    restroManager: {},


    restroStaff: [],

    restroStaffMember: {}
};

const updateRestros = (state, { payload }) => {
    state.restros = payload?.restroDetail
    state.totalRecords = payload?.totalRecords
    state.totalPages = payload?.totalPages
    state.currentPage = payload?.currentPage
};


const selectedRestro = (state, { payload }) => {
    state.restro = payload;
    state.restroManagers = payload?.managers;
}


const selectedRestroManager = (state, { payload }) => {
    state.restroManager = payload;
}

const updateRestroStaff = (state, { payload }) => {
    state.restroStaff = payload
};

const selectedRestroStaffMember = (state, { payload }) => {
    state.restroStaffMember = payload;
}





export const restrosSlice = createSlice({
    name: "restros",
    initialState,
    reducers: {
        getRestro: (state, { payload }) => {
            state.restros = payload
        },
        deleteRestro: (state, { payload }) => {
            state.restros = state.restros.filter(user => user._id !== payload)
        },


        changeStatus: (state, { payload }) => {
            const { idx, status } = payload;
            const updatedUsers = [...state.restros];
            updatedUsers[idx] = {
                ...updatedUsers[idx],
                status,
            };

            return {
                ...state,
                restros: updatedUsers,
            };
        },


        changeStaffMemberStatus: (state, { payload }) => {
            const { index, status } = payload;

            const updatedUsers = [...state.restroStaff];
            console.log({ ...updatedUsers[index]?.user_detail })
            updatedUsers[index] = {
                ...updatedUsers[index],
                user_detail: {
                    ...updatedUsers[index]?.user_detail,
                    status,
                }
            };

            return {
                ...state,
                restroStaff: updatedUsers,
            };
        },
        deleteRestroManager: (state, { payload }) => {
            state.restroStaff = state.restroStaff.filter(user => user?.user_detail?._id !== payload)
        },
        deleteRestroStaffMember: (state, { payload }) => {
            state.restroStaff = state.restroStaff.filter(user => user?.user_detail?._id !== payload)
        }

    },
    extraReducers: (builder) => {
        builder.addCase(getRestrosData.fulfilled, updateRestros)
            .addCase(getById.fulfilled, selectedRestro)
            .addCase(getManagerById.fulfilled, selectedRestroManager)
            .addCase(getRestroStaffData.fulfilled, updateRestroStaff)
            // .addCase(getRestroStaffById.fulfilled, selectedRestroStaff)
            .addCase(getRestroStaffMemberById.fulfilled, selectedRestroStaffMember);
    },
});

export const {
    getRestro, deleteRestro, editUser, changeStatus,
    deleteRestroStaffMember, changeStaffMemberStatus,
    deleteManager
} = restrosSlice.actions;

export default restrosSlice.reducer;
