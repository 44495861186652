import AxiosInterceptor from "../utils/AxiosInterceptor";

const subscriptionsApi = {
    add: (value) => AxiosInterceptor().post(`/admin/subscription/add`, value),
    edit: (value) => AxiosInterceptor().post(`/admin/subscription/edit`, value),
    delete: (value) => AxiosInterceptor().post(`/admin/subscription/delete`, value),
    getAll: (values) => AxiosInterceptor().post(`/admin/subscription/list`, values),
    getById: (values) => AxiosInterceptor().post(`/admin/subscription/detail`, values),
    changeStatus: (values) => AxiosInterceptor().post(`/admin/subscription/change-status`, values),
};

export default subscriptionsApi;
