import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify"
// @mui
import {
    Card,
    Stack,
    Avatar,
    Button,
    Container,
    Typography,
    TextField,
    Link as MLink,
    Icon,
    Grid,
    CardContent,
    CardHeader,
    InputAdornment,
    IconButton,
    Box
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../../../sections/@dashboard/user';






import Field from '../../../components/Inputs/Field';
import PasswordField from '../../../components/Inputs/PasswordField';
import BackButton from '../../../components/Buttons/BackButton';

// !utils
import errorHandler from '../../../utils/errorHandler';
import { API_BASE, MOBILE_REGEX, NOSPACE_REGEX, PASSWORDS_REGEX } from '../../../utils/constants';
import { MESSAGE } from '../../../utils/validationMessage';
import { complexEmailRegex } from '../../../utils/emailCheck';


// !states 
import restrosApi from "../../../api/restrosService"


const validationSchema = Yup.object().shape({
    name: Yup.string().strict(true).max(30, "First name should be less than 30 chars").required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
    email: Yup.string()
        .required(MESSAGE.REQUIRED)
        .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE)
        .test("is-email", MESSAGE.EMAIL, (val) => complexEmailRegex(val))
        .max(255),
    password: Yup.string().required(MESSAGE.REQUIRED).matches(PASSWORDS_REGEX, MESSAGE.PASSWORD),
    phone: Yup.string().required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE).matches(MOBILE_REGEX, MESSAGE.PHONE),
    restaurantName: Yup.string().strict(true).max(30, "First name should be less than 30 chars").required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
    licenceNumber: Yup.string().matches(/^[a-zA-Z0-9]*$/, 'License number must be alphanumeric').required('This field is required'),

    // address: Yup.string().strict(true).max(255, "Street address should be less than 255 chars").required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
    // addresses: Yup.array().min(1).of(Yup.object().shape({
    //     address: Yup.string().trim().required('Address can\'t be empty')
    // }))
});



export default function RestroPage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { restro } = useSelector(state => state.restros)
    const arrayRef = useRef()
    const [showPassword, setShowPassword] = useState(false);
    const initialValues = {
        name: "",
        email: "",
        password: "",
        phone: "",
        // subscription: "",
        restaurantName: "",
        // address: "",
        licenceNumber: "",
        // addresses: [{ address: "" }],
    };


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const response = await restrosApi.add(values);
                toast.success(response?.data?.message)
                navigate("/dashboard/restro", { replace: true })
            } catch (err) {
                errorHandler(err);
                setSubmitting(false);
            }
        },
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, values } = formik;




    return (
        <>
            <Container>
                <Card>
                    <CardHeader title={<BackButton text="Add Restaurant Owner" variant="h6" />} />
                    <CardContent>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={3} >
                                    <Grid item sm={12} md={6} >
                                        <Field
                                            name="name"
                                            label="Name"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={6} >
                                        <Field
                                            name="email"
                                            label="Email"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={6} >
                                        <Field
                                            name="phone"
                                            label="Phone"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>

                                    {/* ------ PASSWORD -------- */}
                                    {/* ------ PASSWORD -------- */}
                                    <Grid item sm={12} md={6} >
                                        <PasswordField
                                            name="password"
                                            label="Password"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>

                                    <Grid item sm={12} md={6} >
                                        <Field
                                            name="restaurantName"
                                            label="Restaurant Name"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={6} >
                                        <Field
                                            name="licenceNumber"
                                            label="Licence Number"

                                            errors={errors}
                                            touched={touched}
                                            getFieldProps={getFieldProps}
                                        />
                                    </Grid>

                                </Grid>

                                <Box my={4} />

                                {/* <Grid item sm={12} md={6} >
                                    <Button
                                        // size="large"
                                        className="add-address btn  btn-secondry"
                                        type="button"
                                        // fullWidth
                                        // disabled={errors?.addresses?.length}
                                        variant="contained"
                                        color="success"
                                        sx={{ color: 'white' }}
                                        onClick={() =>
                                            arrayRef.current({
                                                address: ""
                                            })
                                        }
                                    >
                                        Add Branch
                                    </Button>
                                </Grid> */}


                                {/* <MultiField
                                    name="addresses"
                                    label="Address"
                                    valKey="address"

                                    values={values}
                                    touched={touched}
                                    errors={errors}
                                    getFieldProps={getFieldProps}
                                    refFunc={arrayRef}
                                /> */}




                                <Stack spacing={2} mt={3} direction="row" alignItems="center" justifyContent="flex-end">
                                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>Add </LoadingButton>
                                    {/* <Button type="button" variant="outlined" onClick={() => navigate(-1)}>
                                        Back
                                    </Button> */}
                                </Stack>
                            </Form>
                        </FormikProvider>
                    </CardContent>
                </Card>
            </Container>
        </>
    );
}



/*
                                { <FieldArray name="addresses">
                                    {
                                        ({ push, remove }) => {
                                            arrayRef.current = push
                                            return (<Grid container spacing={3} mt={2}>
                                                {values?.addresses.length ? values?.addresses.map((val, i) => {
                                                    return (
                                                        <>
                                                            <Grid item sm={12} md={6} >
                                                                <div className="flex">
                                                                    <Box sx={{ display: 'flex' }}>
                                                                        <TextField InputLabelProps={{ shrink: true }} name={`addresses[${i}].address`} id={`addresses[${i}].address`} label={`Address ${i + 1}`} fullWidth {...getFieldProps(`addresses[${i}].address`)} error={Boolean(errors?.addresses?.length && errors?.addresses[i]?.address)} helperText={errors?.addresses?.length && errors?.addresses[i]?.address} />
                                                                        {values?.addresses?.length !== 1 &&
                                                                            <Button onClick={() => remove(i)} sx={{ color: 'error.main', ml: 2, height: 55 }} variant="outlined" color="error" className="btn btn-danger mr-2">
                                                                                Delete
                                                                            </Button>
                                                                        }
                                                                    </Box>
                                                                </div>
                                                            </Grid>
                                                        </>
                                                    )
                                                }) : null}
                                            </Grid>)
                                        }
                                    }
                                </FieldArray> 
*/