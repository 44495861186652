import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import paymentsApi from "../api/paymentService";


const type = "payments";
const singletype = "payment";


export const getPaymentsData = createAsyncThunk(`${type}/getPaymentsData`, async (value) => {
    const { data } = await paymentsApi.getAll(value);
    return data.response
});

export const getById = createAsyncThunk(`${type}/getById`, async (value) => {
    const { data } = await paymentsApi.getById(value);
    return data.response
});



const initialState = {
    [type]: [],
    [singletype]: {}
};

const update = (state, { payload }) => {
    state[type] = payload;
}

const selectedOne = (state, { payload }) => {
    state[singletype] = payload;
}

export const paymentsSlice = createSlice({
    name: type,
    initialState,
    reducers: {
        getPayments: (state, { payload }) => {
            state[type] = payload
        },
        deletePayment: (state, { payload }) => {
            state[type] = state[type].filter(allergy => allergy._id !== payload)
        },
        changeStatus: (state, { payload }) => {
            const { id, status } = payload;
            const cmsIndex = state[type].findIndex((allergy) => allergy._id === id);

            if (cmsIndex !== -1) {
                const updatedCms = [...state[type]];
                updatedCms[cmsIndex] = {
                    ...updatedCms[cmsIndex],
                    status,
                };

                return {
                    ...state,
                    [type]: updatedCms,
                };
            }

            return state;
        },


    },
    extraReducers: (builder) => {
        builder.addCase(getPaymentsData.fulfilled, update)
            .addCase(getById.fulfilled, selectedOne);
    },
});

export const { getPayments, deletePayment, changeStatus } = paymentsSlice.actions;

export default paymentsSlice.reducer;
