import AxiosInterceptor from "../utils/AxiosInterceptor";

const apiUsers = {
    add: (value) => AxiosInterceptor().post(`/admin/user/add`, value),
    edit: (value) => AxiosInterceptor().post(`/admin/user/edit`, value),
    delete: (value) => AxiosInterceptor().post(`/admin/user/delete`, value),
    getAll: (values) => AxiosInterceptor().post(`/admin/user/list`, values),
    getById: (values) => AxiosInterceptor().post(`/admin/user/detail`, values),
    changeStatus: (values) => AxiosInterceptor().post(`/admin/user/change-status`, values),
};

export default apiUsers;
