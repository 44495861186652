import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify"
// @mui
import {
    Card,
    Container,
    Link as MLink,
    CardContent,
    CardHeader,
    Box,
    Typography,
    Avatar,
    Paper
} from '@mui/material';

import { useNavigate, useParams } from 'react-router-dom';

import BackButton from '../../../components/Buttons/BackButton';



// !states 
import { getById } from "../../../features/RestrosSlice"
import Iconify from '../../../components/iconify/Iconify';






export default function RestroOwnerViewPage() {
    const { restroId } = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { restro } = useSelector(state => state.restros)

    useEffect(() => {
        dispatch(getById({ id: restroId }))
    }, [restroId])

    return (
        <>
            <Container>
                <Card>
                    <CardHeader title={<BackButton text="View Detail" variant="h6" />} />

                    <CardContent>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}>
                            <Avatar alt="Super Admin Profile Picture" sx={{ width: 150, height: 150 }} >
                                {restro?.name}
                            </Avatar>
                            <Typography variant="h3" sx={{ mt: 1 }}>{restro?.restaurantName}</Typography>
                        </Box>
                        <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
                            <Typography variant="h3" color="textPrimary">{restro?.restaurantName}'s Information</Typography>
                            <Box sx={{ display: 'flex', mt: 2 }}>
                                <Typography color="textSecondary">Name: </Typography>
                                <Typography color="textSecondary" variant=''> {restro?.name}</Typography>
                            </Box>
                            <Typography color="textSecondary">Email: {restro?.email}</Typography>
                            <Typography color="textSecondary">Phone: {restro?.phone}</Typography>
                            <Typography color="textSecondary">Licence Number: {restro?.licenceNumber}</Typography>
                        </Paper>
                    </CardContent>
                </Card>
            </Container >
        </>
    );
}

